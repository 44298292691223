import { getHomeData, usePreviewSubscription } from '@/lib/sanity'
import { getHomeDataQuery } from '@/lib/queries'
import { useRouter } from 'next/router'

import Layout from '@/components/Layout/MainLayout'
import Sponsors from '@/components/Composition/Sponsors'
import StandardMeta from '@/components/Common/Meta/Standard'
import AboutHero from '@/components/Common/AboutHero'
import styles from '@/components/Common/AboutHero/AboutHero.module.scss'
import ButtonStandard from '@/components/Primitive/ButtonStandard'
import Type from '@/components/Primitive/Type'
import Testimonials from '@/components/Common/Testimonials'
import SpecialOffer from '@/components/Common/SpecialOffer'
// import EventsOffers from '@/components/Common/EventsOffers'
import JoinUs from '@/components/Common/JoinUs'
import Container from '@/components/Primitive/Container'
import BlockContent from '@/components/Primitive/BlockContent'
import Stack from '@/components/Primitive/Stack'

const Home = ({ homePageData }) => {
  const router = useRouter()
  const { data: pageData } = usePreviewSubscription(getHomeDataQuery, {
    initialData: homePageData,
    enabled: router.query.preview !== null
  })
  const { siteSettings, sponsors, home } = pageData
  const { openGraph } = siteSettings

  return (
    <Layout preFooter transparentNav>
      <StandardMeta {...openGraph} />
      <Stack gap="huge">
        <AboutHero imageUrl="/images/about-component-image.jpg">
          <div className={styles.Body}>
            <p className={styles.SectionName}>ABOUT</p>
            <Type as="h2" size="titleLarge" bold>
              Join our exciting Video Games Design club & unleash your
              creativity.
            </Type>
            <p>
              Immerse yourself and let your creativity shine, collaborate and
              mix with like minded individuals across your summer holidays.
            </p>
            <ButtonStandard to="/about" size="mediumSmall">
              About us
            </ButtonStandard>
          </div>
        </AboutHero>

        {home.body && (
          <Container size="mediumLarge" gutter center>
            <BlockContent blocks={home.body} />
          </Container>
        )}

        {home.specialOffer && <SpecialOffer {...home.specialOffer} />}
        {home.specialOffer2 && <SpecialOffer {...home.specialOffer2} />}

        <JoinUs />
        <Sponsors sponsors={sponsors} />
        {/* <EventsOffers /> */}
        <Testimonials reviews={home.reviews} />
      </Stack>
    </Layout>
  )
}

export const getStaticProps = async () => {
  const homePageData = await getHomeData()

  return {
    props: {
      homePageData: homePageData
    },
    revalidate: 60 * 30 // 30 minutes
  }
}

export default Home
