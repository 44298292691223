import React from 'react'
import Container from '@/components/Primitive/Container'
import styles from './SpecialOffer.module.scss'
import { node } from 'prop-types'
import Block from '@/components/Composition/Block'
import Image from '@/components/Primitive/Image'
import ButtonStandard from '@/components/Primitive/ButtonStandard'
import Type from '@/components/Primitive/Type'

const SpecialOffer = ({ title, body, image, ctaLabel, ctaLink, alt }) => {
  if (!title || !body || !image) return null
  return (
    <Container
      className={styles.Container}
      size="mediumLarge"
      center
      gutter
      as="section"
    >
      <div className={styles.SpecialOfferWrapper}>
        <Type as="p" size="base" className={styles.Label}>
          Special offer
        </Type>
        <div className={styles.SpecialOffer}>
          <div className={styles.Body}>
            <Type as="h2" size="displayLarge" className={styles.Title}>
              {title}
            </Type>
            <Block className={styles.BlockContent} body={body} />
            {ctaLink && ctaLabel && (
              <ButtonStandard href={ctaLink}>{ctaLabel}</ButtonStandard>
            )}
          </div>
          <Image
            className={styles.Image}
            image={image}
            alt={alt}
            width={400}
            height={300}
            objectFit="cover"
          />
        </div>
      </div>
    </Container>
  )
}

SpecialOffer.propTypes = {
  pageData: node.isRequired
}

export default SpecialOffer
