import { node, oneOf } from 'prop-types'
import classNames from 'classnames'
import React from 'react'

import styles from './Stack.module.scss'

const gaps = ['small', 'medium', 'mediumLarge', 'large']

/**
 * A wrapper component which evenly vertically-spaces its children.
 */
const Stack = ({ children, gap, className, ...other }) => (
  <div
    className={classNames(styles.Stack, gap && styles[gap], className)}
    {...other}
  >
    {children}
  </div>
)

Stack.defaultProps = {
  gap: 'medium'
}

Stack.propTypes = {
  children: node.isRequired,
  gap: oneOf(gaps)
}

export default Stack
